<template>
  <div class="login-wrap" v-loading="loading">
    <!-- <div class="webName">产业链大数据平台</div> -->
    <div class="ms-register" v-if="isBind">
      <div class="ms-title">绑定账户</div>
      <el-form
        :model="paramRegister"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username" v-if="whichStep == 1">
          <el-input v-model="paramRegister.username" placeholder="请设置用户名">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password_confirm" v-if="whichStep == 0">
          <el-tooltip
            class="item"
            effect="dark"
            content="用于绑定账户"
            placement="right"
          >
            <el-input placeholder="请输入邮箱" v-model="paramRegister.email">
              <el-button slot="prepend" icon="el-icon-message"
                ><span style="color: red">*</span></el-button
              >
            </el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="company_name" v-if="whichStep == 1">
          <el-input
            placeholder="请输入企业名"
            v-model="paramRegister.company_name"
          >
            <el-button
              slot="prepend"
              icon="el-icon-office-building"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password_confirm" v-if="whichStep == 0">
          <el-input placeholder="请输入手机号" v-model="paramRegister.mobile">
            <el-button slot="prepend" icon="el-icon-mobile-phone"
              ><span style="color: red">*</span></el-button
            >
          </el-input>
        </el-form-item>
        <!-- <el-form-item prop="name">
          <el-input v-model="paramRegister.real_name" placeholder="请输入姓名">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
        </el-form-item> -->
        <div class="login-btn">
          <!-- <el-button type="primary" @click="registerForm()">注册</el-button>
           -->
          <el-button
            type="primary"
            @click="handleNextStep()"
            >下一步</el-button
          >
          <p>
          </p>
        </div>
      </el-form>
    </div>
    <div class="ms-register" v-if="!isBind">
      <div class="ms-title">注册</div>
      <el-form
        :model="paramRegister"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username" v-if="whichStep == 1">
          <el-input v-model="paramRegister.username" placeholder="请设置用户名">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password_confirm" >
          <el-tooltip
            class="item"
            effect="dark"
            content="用于激活账户"
            placement="right"
          >
            <el-input placeholder="请输入邮箱" v-model="paramRegister.email">
              <el-button slot="prepend" icon="el-icon-message"
                ><span style="color: red">*</span></el-button
              >
            </el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="company_name" >
          <el-input
            placeholder="请输入企业名"
            v-model="paramRegister.company_name"
          >
            <el-button
              slot="prepend"
              icon="el-icon-office-building"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password_confirm" >
          <el-input placeholder="请输入手机号" v-model="paramRegister.mobile">
            <el-button slot="prepend" icon="el-icon-mobile-phone"
              ><span style="color: red">*</span></el-button
            >
          </el-input>
        </el-form-item>
        <!-- <el-form-item prop="name">
          <el-input v-model="paramRegister.real_name" placeholder="请输入姓名">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
        </el-form-item> -->
        <el-form-item prop="password" v-if="whichStep == 0">
          <el-tooltip
            class="item"
            effect="dark"
            content="密码格式：6-20位，且需同时包含字母，数字"
            placement="right"
          >
            <el-input
              type="password"
              placeholder="请设置密码"
              v-model="paramRegister.password"
            >
              <el-button slot="prepend" icon="el-icon-lock"
                ><span style="color: red">*</span></el-button
              >
            </el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="password" v-if="whichStep == 0">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="paramRegister.password2"
          >
            <el-button slot="prepend" icon="el-icon-lock"
              ><span style="color: red">*</span></el-button
            >
          </el-input>
        </el-form-item>
        <div class="imageCodeBox" v-if="whichStep == 1">
          <img class="imageCode" :src="imageCodeUrl" @click="getImageCode" />
        </div>
        <div class="login-btn">
          <!-- <el-button type="primary" @click="registerForm()">注册</el-button>
           -->
          <el-button
            type="primary"
            @click="wechatRegister()"
            v-if="whichStep == 0"
            >下一步</el-button
          >
          <p>
          </p>
        </div>
      </el-form>
    </div>
    <el-dialog title="提示" :visible.sync="showTips" width="40%">
      您的账号暂无访问权限，请发送邮件到【 info@suwen.ai 】获取权限！
    </el-dialog>
    <div class="copyright">
      <img class="copyright-img" src="@/assets/images/copyright.png" />
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { v4 as uuidv4 } from "uuid";
import cookie from "@/utils/common.js";
import utils from "@/utils/utils.js";
export default {
  data() {
    return {
      env: "",
      showTips: false,
      whichStep: 0, // 注册进行到第几步
      isSendCode: false, // 是否点击发送验证码
      loading: false,
      isBind: true,
      isFindPwd: false,
      uuid: "",
      imageCodeUrl: "",
      param: {
        username: "",
        password: "",
        remembered: 1,
        system: "kjld_inside_env",
      },
      paramRegister: {
        unionid: "",
        username: "",
        password: "",
        new_password2: "",
        image_code: "",
        email: "",
        company_name: "",
        real_name: "",
        mobile: "",
      },
      paramForget: {
        email: "",
        uuid: "",
        image_code: "",
        num_code: "",
        password: "",
        password_confirm: "",
      },
      serverOptionsTotal: [
        {
          value: "企业舆情",
          label: "企业舆情",
          route: "/company_news",
          server: "cyl_qyyq",
        },
        {
          value: "产业舆情",
          label: "产业舆情",
          route: "/industry_news",
          server: "cyl_cyyq",
        },
        {
          value: "产业风险",
          label: "产业风险",
          route: "/industry_risk",
          server: "cyl_cyfx",
        },
        {
          value: "企业图谱",
          label: "企业图谱",
          route: "/company_graph",
          server: "cyl_qytp",
        },
        {
          value: "行业图谱",
          label: "行业图谱",
          route: "/industry_graph",
          server: "cyl_hytp",
        },
        {
          value: "产业图谱",
          label: "产业图谱",
          route: "/category_graph",
          server: "cyl_cytp",
        },
        {
          value: "产业招商",
          label: "产业招商",
          route: "/industry_business",
          server: "cyl_cyzs",
        },
      ],
      // rules: {
      //   username: [
      //     { required: true, message: "请输入用户名", trigger: "blur" },
      //   ],
      //   password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      // },
      // rules_register: {
      //   username: [
      //     { required: true, message: "请输入用户名", trigger: "blur" },
      //     { min:5,max:22, message: "长度需在5-22个字符之间" }
      //   ],
      //   password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      // },
    };
  },
  created() {
    this.paramRegister.unionid = this.$route.query.unionid
  },
  methods: {
    wechatRegister() {
      api.wechatRegister(this.paramRegister).then(res => {
        console.log("wechatRegister", res)
      })
    },
    // 点击下一步（绑定/注册账户）
    handleNextStep() {
      let emailReg =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      console.log("注册第一步表单", this.paramRegister);
      if (!this.paramRegister.email) {
        this.$message.warning("请填写邮箱！");
      } else if (!this.paramRegister.mobile) {
        this.$message.warning("请填写手机号！");
      } else {
        api.bindWechat(this.paramRegister).then(res => {
          console.log("handleNextStep", res)
          if (res.code == 400) {
            this.$message.warning("该邮箱和手机号没有对应的账户，请注册账户！");
            this.paramRegister.password = ""
            this.isBind = false
          }
        })
      }
    },
    // 点击发送验证码
    sendEmailCode() {
      let params = {
        image_code: this.paramForget.image_code,
        uuid: this.uuid,
        email: this.paramForget.email,
      };
      api
        .sendEmailCode(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("6位验证码已发至您的邮箱，请注意查收！");
            this.isSendCode = true;
          } else {
            this.$message.error(res.errmsg);
          }
        })
        .catch((err) => {
          this.$message.error("服务器出错", err);
        });
    },
    // 忘记密码修改
    changePwd() {
      let params = {
        email: this.paramForget.email,
        num_code: this.paramForget.num_code,
        new_password: this.paramForget.password,
        new_password2: this.paramForget.password_confirm,
      };
      api
        .changePwd(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("修改密码成功!");
            this.isBind = false;
            this.isFindPwd = false;
          } else {
            this.$message.error(res.errmsg);
          }
        })
        .catch((err) => {
          this.$message.error("服务器出错", err);
        });
    },
    // 获取验证码
    getImageCode() {
      api
        .getImageCode(this.uuid)
        .then((res) => {
          // console.log("验证码", res)
          this.imageCodeUrl = "data:image/jpg;base64," + res;
        })
        .catch((err) => {
          this.$message.error("服务器出错", err);
        });
    },
    // 获取首页url
    getHomeIUrl() {
      let path = "";
      let server = cookie.getCookie("server").split(",");
      for (let j = 0; j < this.serverOptionsTotal.length; j++) {
        for (let i = 0; i < server.length; i++) {
          if (this.serverOptionsTotal[j].server == server[i]) {
            path = this.serverOptionsTotal[j].route;
            return path;
          }
        }
      }
    },
    // 登录
    submitForm() {
      // this.$cookie.delete('token')
      this.$refs.login.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (
            this.param.username == "admintest" &&
            this.param.password == "admintest"
          ) {
            cookie.setCookie("account", this.param.username);
            cookie.setCookie(
              "server",
              "cyl_qyyq,cyl_cyzs,cyl_cytp,cyl_qytp,cyl_qyzl"
            );
            cookie.setCookie("permissions", "[99]");
            cookie.setCookie("module", "is_master");
            cookie.setCookie("role", "admin");
            this.$router.push("/index");
          } else {
            api
              .login(this.param)
              .then(async (res) => {
                if (res.code == 0) {
                  console.log("res.permissions", res.permissions);
                  if (res.server.length == 0) {
                    this.showTips = true;
                    this.loading = false;
                    return;
                  }
                  console.log("login", res);
                  this.$message.success("登录成功");
                  // cookie.setCookie("account", this.param.username)
                  cookie.setCookie("account", res.user_info.username);
                  cookie.setCookie("is_normal", res.user_info.is_normal)
                  cookie.setCookie("server", JSON.stringify(res.server));
                  cookie.setCookie("industry_graph", res.industry_graph);
                  sessionStorage.setItem("industry_graph", res.industry_graph);
                  cookie.setCookie("industry_news", res.industry_news);
                  cookie.setCookie(
                    "permissions",
                    JSON.stringify(res.permissions)
                  );
                  cookie.setCookie("token", res.token);
                  cookie.setCookie("module", res.module);
                  cookie.setCookie("role", "admin");
                  // this.$router.push(this.getHomeIUrl());
                  // window.location.reload()
                  // this.$router.push("/index")

                  this.$router.push("/firstPage");
                  this.loading = false;
                } else if (res.code == 2) {
                  this.loading = false;
                  this.$message.warning("账号无权限，请联系管理员！");
                } else {
                  this.loading = false;
                  this.$message.error(res.errmsg);
                }
                // console.log(res)
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$message.error("服务器错误，请重试或联系管理员");
                // alert("账号或密码错误")
              });
          }
        } else {
          this.$message.error("请输入账号和密码");
          console.log("error submit!!");
          return false;
        }
      });
    },
    registerForm() {
      console.log(this.paramRegister);
      // this.$cookie.delete('token')
      if (this.paramRegister.image_code) {
        this.loading = true;
        let param = {
          username: this.paramRegister.username,
          password: this.paramRegister.password,
          password2: this.paramRegister.password_confirm,
          company_name: this.paramRegister.company_name,
          image_code: this.paramRegister.image_code,
          uuid: this.uuid,
          email: this.paramRegister.email,
          real_name: this.paramRegister.real_name,
          mobile: this.paramRegister.mobile,
        };
        api
          .register(param)
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$message.success("注册成功，请注意查收账户激活邮件！");
              this.isBind = false;
              this.loading = false;
            } else {
              this.loading = false;
              this.$message.error(res.errmsg);
              this.getImageCode();
            }
            // console.log(res)
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.$message.error("服务器出错");
            this.getImageCode();
            // alert("账号或密码错误")
          });
      } else {
        this.$message.warning("请输入验证码");
        console.log("error submit!!");
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.login-wrap {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url(../assets/images/bg.jpg);
  background-size: 100% 100%;
  .el-button--success {
    background-color: #2BA245;
    border-color: #2BA245;
  }
  .ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: rgb(65, 63, 63);
    border-bottom: 1px solid #ddd;
  }
  .webName {
    position: absolute;
    width: 100vw;
    color: #333333;
    display: flex;
    justify-content: center;
    top: 25%;
    font-size: 2vw;
  }
  .ms-login,
  .ms-register {
    position: absolute;
    left: 50%;
    top: 55%;
    width: 400px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
  }
  .ms-register {
    top: 48%;
  }
  .ms-content {
    padding: 30px 30px;
  }
  .login-btn {
    text-align: center;
  }
  .login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
  }
  .login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
  }
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  .imageCodeBox {
    display: flex;
    align-items: center;
    // flex-direction: row-reverse;
  }
  .imageCode {
    width: 150px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  .copyright {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 5px;
    &-img {
      width: 500px;
    }
  }
}
</style>